import Loader from 'react-loader-spinner'

function Preloader() {
    const style = ({ props }) => ({
        border: "10px solid black",
        borderRadius: "100%",
        height: props.diameter,
        left: "50%",
        opacity: props.opacity,
        position: "absolute",
        top: "50%",
        transform: "translateX(-50%) translateY(-50%)",
        width: props.diameter,
        zIndex: 1
    });

    return (
        <div style={{ height: '100vh', width: '100vw', color: 'black', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ margin: 'auto', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ font: '1.2em \'signature\'', fontSize: '140px' }}>
                        <span>JM Ricome</span>
                    </div>
                </div>
                <div>
                    <Loader type="Bars"
                            color="darkred"
                            height={100}
                            width={100}
                            timeout={3000} />
                </div>
            </div>
        </div>
    )
}

export default Preloader;
