import { motion } from 'framer-motion';
import ReCAPTCHA from 'react-google-recaptcha';
import { createRef } from 'react';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import Obfuscate from 'react-obfuscate';
const axios = require('axios');

function Contact() {

    const recaptchaRef = createRef();

    const onSubmit = async (form) => {
        const token = await recaptchaRef.current.executeAsync();
        axios.post('contact', {...form, token}).then(() => console.log('message sent !'));
        console.log(form, token);
    }

    return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh' }}>
            <div style={{
                backgroundColor: 'rgba(255,255,255,0.9)',
                height: '400px',
                width: '1000px',
                margin: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <div style={{ margin: 'auto', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ color: 'darkred' }}>
                        <p>Contact</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div>
                            <Form
                                onSubmit={onSubmit}
                                render={({ handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <div style={{ display: 'flex', flexDirection: 'column', color: 'black', marginBottom: '100px' }}>
                                        {/*<div style={{ display: 'flex', flexDirection: 'row' }}>*/}
                                        {/*    <label htmlFor="name" style={{ width: '200px' }}>Votre nom : </label>*/}
                                        {/*    <Field name="name" component="input" />*/}
                                        {/*</div>*/}
                                        {/*<div style={{ display: 'flex', flexDirection: 'row' }}>*/}
                                        {/*    <label htmlFor="email" style={{ width: '200px' }}>Votre email : </label>*/}
                                        {/*    <Field name="email" component="input" />*/}
                                        {/*</div>*/}
                                        {/*<div style={{ display: 'flex', flexDirection: 'row' }}>*/}
                                        {/*    <label htmlFor="message" style={{ width: '200px' }}>Votre message :</label>*/}
                                        {/*    <Field name="message" type="textarea" render={props => <textarea {...props.input} name="Text1" cols="70" rows="10" id="message" /> } />*/}
                                        {/*</div>*/}
                                        {/*<div style={{ display: 'flex', flexDirection: 'row' }}>*/}
                                        {/*    <Field name="captcha" render={() => (*/}
                                        {/*        <ReCAPTCHA ref={recaptchaRef} sitekey="6LdF7y8aAAAAAJ7oevoTt6qnse_aMCLnkOKk_us5" size="invisible" />*/}
                                        {/*    )}*/}
                                        {/*    />*/}
                                        {/*</div>*/}
                                        {/*<div style={{ textAlign: 'right' }}>*/}
                                        {/*    <button type="submit">Envoyer</button>*/}
                                        {/*</div>*/}
                                        <p>Pour me contacter, vous pouvez m'écrire un mail à l'adresse suivante : </p>
                                        <p style={{ textAlign: 'center' }}><Obfuscate style={{ fontSize: '20px', color: 'darkred', textDecoration: 'none' }} obfuscate email="jm.ricome@gmail.com" /></p>
                                    </div>
                                </form>
                            )}/>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div style={{ alignItems: 'center', textAlign: 'center' }}>
                                    <div style={{ fontSize: '20px', color: '#000000' }}><span>Jean-Marc Ricome</span></div>
                                    <div style={{ fontSize: '20px', color: '#000000', marginTop: '10px' }}>Tel: <Obfuscate style={{ fontSize: '20px', color: '#000000', textDecoration: 'none' }} obfuscate tel="06 81 77 12 11" /></div>
                                </div>
                                <div style={{ paddingLeft: '40px', color: '#000000', marginLeft: '30px', borderLeft: '5px solid darkred', font: '1.2em \'signature\'', fontSize: '60px' }}>
                                    <span>JM Ricome</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style={{ backgroundColor: 'rgba(255,255,255,0.7)', width: '200px', display: 'flex', flexDirection: 'column', borderLeft: '5px solid darkred', paddingLeft: '10px', paddingTop: '10px', paddingBottom: '10px', justifyContent: 'space-around', top: '40%', left: '10px', position: 'absolute' }}>
            <Link to="/" style={{ textDecoration: 'none', marginBottom: '10px' }} className="App-link">Accueil</Link>
            <Link to="/presentation" style={{ textDecoration: 'none', marginBottom: '10px' }} className="App-link">Présentation</Link>
            <Link to="/contact" style={{ textDecoration: 'none' }} className="App-link">Contact</Link>
        </div>
    </motion.div>
    )
}

export default Contact;
