import './App.css';
import Home from './Home';
import Gallery from './Gallery';
import Contact from './Contact';
import { AnimatePresence } from 'framer-motion';
import { Switch, Route, useLocation } from 'react-router-dom';
import Background from './Background';
import { Suspense } from 'react';
import Preloader from './Preloader';
import Presentation from './Presentation';

const hour = new Date().getHours();
const image = hour < 12 ? 'background-1.jpg' : 'background-1.jpg';

function App() {
    const location = useLocation();
  return (
    <div className="App">
        <Suspense fallback={<Preloader />}>
        <div style={{
            display:'flex',
            flexDirection: 'column',
            width: '100vw',
            height: '100vh',
            minWidth: '800px',
        }}
        >
            <Background />
            <AnimatePresence exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
                <Route path="/" exact component={Home} />
                <Route path="/gallery/" exact component={Gallery} />
                <Route path="/contact/" exact component={Contact} />
                <Route path="/presentation/" exact component={Presentation} />
            </Switch>
            </AnimatePresence>
        </div>
        </Suspense>
    </div>
  );
}

export default App;
