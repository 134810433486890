import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

function Presentation() {
    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh' }}>
                <div style={{
                    backgroundColor: 'rgba(255,255,255,0.9)',
                    height: '600px',
                    width: '1000px',
                    margin: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <div style={{ margin: 'auto', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ color: 'darkred' }}>
                            <p>Portrait</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', margin: '30px', alignItems: 'center' }}>
                            <div style={{ color: 'black', fontSize: '15px' }}>
                                <p>
                                    Né le 18 juin 1959 en Allemagne, il sillonne dès son plus jeune âge, l’Europe et ses capitales emblématiques avec des parents passionnés de culture et d’histoire.
                                </p>
                                <p>
                                    Son père amateur de photographie, lui transmet très tôt le goût de la patience et de l’observation, il comprendra plus tard qu’il s’agissait d’attendre ”le bon moment”, celui où la lumière vient dessiner subtilement le sujet..
                                </p>
                                <p>
                                    ”Écrire avec la lumière” ou photographier !
                                </p>
                                <p>
                                    A l’âge de 10 ans, il arbore fièrement un Kodak 133X et rapidement passe au matériel reflex argentique puis au « Saint Graal » du jeune photographe : le Laboratoire Noir et Blanc.
                                </p>
                                <p>
                                    Parallèlement à sa passion, il suit des études de droit à la faculté de Toulouse, mais très vite, encouragé par son entourage, il intègre l’ETPA (école technique de photo et d’audiovisuel). Il en sort diplômé en 1983 et s’installe la même année dans la Ville rose pour des décennies de photographies publicitaires, de reportages industriels et de prises de vues de mode, efficaces et professionnelles.
                                </p>
                                <p>
                                    Depuis 2017 Jean-Marc Ricome expose dans de nombreux établissements privés.
                                </p>
                                <p>
                                    Aujourd’hui, ce photographe passionné d'architecture privilégie les sujets de fond sur lesquels il pose son regard et apporte son expérience et sa sensibilité pour transporter l’amateur d’art  dans un univers inattendu où la surprise se mêle à la contemplation.
                                </p>
                            </div>
                            <div style={{ marginLeft: '30px' }}>
                                <img src="images/portrait.jpg" height={300} alt="portrait jean marc ricome" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: 'rgba(255,255,255,0.7)', width: '200px', display: 'flex', flexDirection: 'column', borderLeft: '5px solid darkred', paddingLeft: '10px', paddingTop: '10px', paddingBottom: '10px', justifyContent: 'space-around', top: '40%', left: '10px', position: 'absolute' }}>
                <Link to="/" style={{ textDecoration: 'none', marginBottom: '10px' }} className="App-link">Accueil</Link>
                <Link to="/gallery" style={{ textDecoration: 'none', marginBottom: '10px' }} className="App-link">Galerie</Link>
                <Link to="/contact" style={{ textDecoration: 'none' }} className="App-link">Contact</Link>
            </div>
        </motion.div>
    )
}

export default Presentation;
