import * as _ from 'lodash';
import ImageGallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { useCallback, useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import photos from './photos.json';

const selectedImgStyle = {
    transform: "translateZ(0px) scale3d(0.9, 0.9, 1)",
    transition: "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s"
};

function InnerImage(props) {
    const {photo, left, top, margin, onClick, index} = props;
    const [hover, setHover] = useState(false);
    return (
        <div
            style={{ top, left, margin, width: photo.width, height: photo.height, position: 'relative', cursor: hover ? 'pointer' : 'none' }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            key={photo.key}
            onClick={(event) => onClick(event, {photo, index})}
        >
            {
                hover ?
                    (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                            <div style={{ position: 'absolute', width: '100%', height: '100%', left: 0, backgroundColor: 'rgba(110, 0, 0,0.5)' }}></div>
                        </motion.div>
                    ) : null
            }
            <LazyLoadImage
                {...photo}
                effect="opacity"
            />
        </div>
    )
}

function Gallery() {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [selectedType, setSelectedType] = useState(undefined);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const changeSelectectedType = (type) => {
        selectedType === type ? setSelectedType(undefined) : setSelectedType(type)
    }

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <div style={{
                    backgroundColor: 'rgba(255,255,255,0.9)',
                    height: '100vh'
                }}>
                <div className="gallery" style={{ maxWidth: '80%', margin: 'auto', overflowY: 'scroll', minHeight: '500px', maxHeight: '98%', scrollbarWidth: '0px' }}>
                    <div style={{ color: 'darkred', fontSize: '30px', textAlign: 'center' }}>
                        <div>
                            <p>GALERIE</p>
                        </div>
                    </div>
                    <ImageGallery
                        margin={10}
                        direction="row"
                        targetRowHeight={250}
                        photos={selectedType ? _.filter(photos, ['type', selectedType]) : photos}
                        onClick={openLightbox}
                        renderImage={(props) => (
                            <InnerImage {...props} />
                        )}
                    />
                    <ModalGateway>
                        {viewerIsOpen ? (
                            <Modal onClose={closeLightbox}>
                                <Carousel
                                    currentIndex={currentImage}
                                    views={photos.map(x => ({
                                        ...x,
                                        srcset: x.srcSet,
                                        caption: x.title
                                    }))}
                                />
                            </Modal>
                        ) : null}
                    </ModalGateway>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', borderLeft: '5px solid darkred', paddingLeft: '10px', paddingTop: '10px', paddingBottom: '10px', justifyContent: 'space-around', top: '40%', left: '10px', position: 'absolute' }}>
                    <Link to="/" style={{ textDecoration: 'none', marginBottom: '10px' }} className="App-link">Accueil</Link>
                    <Link to="/presentation" style={{ textDecoration: 'none', marginBottom: '10px' }} className="App-link">Présentation</Link>
                    <Link to="/contact" style={{ textDecoration: 'none' }} className="App-link">Contact</Link>
                </div>
            </div>
        </motion.div>
    )
}

export default Gallery;

