import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

function Home() {
    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '150px', backgroundColor: 'rgba(255,255,255,0.7)', width: '800px', padding: '30px' }}>
                <div style={{ paddingRight: '20px', marginRight: '30px', borderRight: '5px solid darkred', font: '1.2em \'signature\'', fontSize: '140px' }}>
                    <span>JM Ricome</span>
                </div>
                <div>
                    <div style={{ fontSize: '40px', color: '#000000', marginBottom: '10px' }}><span>Jean-Marc Ricome</span></div>
                    <div style={{ fontSize: '20px', display: 'flex', flexDirection: 'column' }}>
                        <Link to="presentation" style={{ textDecoration: 'none', marginBottom: '5px' }}>
                            <span className="App-link">Présentation</span>
                        </Link>
                        <Link to="gallery" style={{ textDecoration: 'none', marginBottom: '5px' }}>
                            <span className="App-link">Galerie</span>
                        </Link>
                        <Link to="contact" style={{ textDecoration: 'none' }}>
                            <span className="App-link">Contact</span>
                        </Link>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default Home;
