import { Suspense, useEffect, useState } from 'react';
import { useTransition, animated, config } from 'react-spring';
import { useImage } from 'react-image';

function Background() {
    const [index, set] = useState(0);
    const slides = [
        { id: 0, image: useImage({srcList: ['images/backgrounds/background-1.jpg'], useSuspense: true}) },
        { id: 1, image: useImage({srcList: ['images/backgrounds/background-2.jpg'], useSuspense: true}) },
        { id: 2, image: useImage({srcList: ['images/backgrounds/background-3.jpg'], useSuspense: true}) },
        { id: 3, image: useImage({srcList: ['images/backgrounds/background-4.jpg'], useSuspense: true}) },
    ]
    const transitions = useTransition(slides[index], item => item.id, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: config.molasses,
    })
    //useEffect(() => setTimeout(() => setCurrentBackgroundIndex(currentBackgroundIndex === 5 ? 1 : currentBackgroundIndex + 1), 5000), [currentBackgroundIndex]);
    useEffect(() => void setInterval(() => set(state => (state + 1) % 4), 5000), [])

    return (
        <div style={{ zIndex: '-100' }}>
            {transitions.map(({item, props, key}) => (
                <animated.div
                    key={item.id}
                    className="bg"
                    style={{...props, backgroundImage: `url(${item.image.src})`}}
                />
            ))
            }
        </div>
    );
}


export default Background;
